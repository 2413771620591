import { ref } from "@vue/composition-api";

export default function useForms() {
  const passwordFieldType = ref("password");

  const togglePasswordVisibility = () => {
    passwordFieldType.value = passwordFieldType.value === "password" ? "text" : "password";
  };

  return {
    passwordFieldType,
    togglePasswordVisibility,
  };
}
